






















































































import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import type { Image, Media } from 'organisms/MediaBanner/models';
import RatioWrapper from 'templates/RatioWrapper.vue';

import { VisualPosition } from '~/diptyqueTheme/types/contentful';

export default defineComponent({
  name: 'MediaBanner',
  components: { RatioWrapper },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    audio: {
      type: [Object, null] as PropType<Media | null>,
      default: null
    },
    video: {
      type: [Object, null] as PropType<Media | null>,
      default: null
    },
    image: {
      type: [Object, null] as PropType<Image | null>,
      default: null
    },
    unmuteButtonText: {
      type: String,
      default: 'Unmute'
    },
    visualPosition: {
      type: String as PropType<VisualPosition>,
      default: VisualPosition.DEFAULT
    },
    visualRatio: {
      type: String,
      default: '1:1'
    }
  },

  setup(props) {
    const videoRef = ref<HTMLVideoElement | null>(null);

    const isMuted = ref(true);
    const isPlaying = ref(false);

    const isMediaLoaded = ref(false);

    const onMediaLoaded = () => {
      isMediaLoaded.value = true;
    };

    const onMediaLoading = () => {
      isMediaLoaded.value = false;
    };

    const isVideo = computed(() => !!props.video?.src);

    const media = computed(() => (isVideo.value ? props.video : props.audio));

    const play = () => {
      videoRef.value.play();
      isPlaying.value = true;
    };

    const onPlay = () => {
      isPlaying.value = true;
    };

    const pause = () => {
      videoRef.value.pause();
      isPlaying.value = false;
      mute();
    };

    const unmute = () => {
      isMuted.value = false;
    };

    const mute = () => {
      isMuted.value = true;
    };

    const togglePlay = () => {
      isPlaying.value ? pause() : play();
    };

    const resetPLayer = () => {
      mute();
      videoRef.value.currentTime = 0;
      play();
    };

    const isContentReversed = computed(
      () => props.visualPosition === VisualPosition.RIGHT
    );

    return {
      videoRef,
      media,
      isVideo,
      isPlaying,
      isMuted,
      isMediaLoaded,
      togglePlay,
      unmute,
      mute,
      onPlay,
      onMediaLoading,
      onMediaLoaded,
      resetPLayer,
      isContentReversed
    };
  }
});
